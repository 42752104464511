
import Layout from '@/layout/index.vue'
import product from './product'

const routes = [
  {
    path: '/',
    redirect: 'overview'
  },
  {
    path: '/404',
    component: () => import('../views/404/index.vue')
  },
  {
    path: '/login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/register',
    component: () => import('../views/login/register.vue')
  },
  {
    path: '/overview',
    component: Layout,
    children: [
      {
        path: '',
        name: 'overview',
        component: () => import('../views/overview/index.vue')
      }
    ]
  },
  {
    path: '/stock',
    component: Layout,
    children: [
      {
        path: '',
        name: 'stock',
        component: () => import('../views/stock/index.vue')
      },
      {
        path: 'add',
        name: 'AddStock',
        component: () => import('../views/stock/edit.vue')
      }
    ]
  },
  {
    path: '/outbound',
    component: Layout,
    children: [
      {
        path: '',
        name: 'outbound',
        component: () => import('../views/outbound/index.vue')
      }
    ]
  },
  {
    path: '/type',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Type',
        component: () => import('../views/type/index.vue')
      }
    ]
  },
  {
    path: '/specs',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Specs',
        component: () => import('../views/specs/index.vue')
      }
    ]
  },
  {
    path: '/company',
    component: Layout,
    children: [
      {
        path: '',
        name: 'company',
        component: () => import('../views/company/index.vue')
      }
    ]
  },
  {
    path: '/template',
    component: Layout,
    children: [
      {
        path: '',
        name: 'template',
        component: () => import('../views/template/index.vue')
      }
    ]
  },
  {
    path: '/order',
    component: Layout,
    children: [
      {
        path: '',
        name: 'order',
        component: () => import('../views/order/index.vue')
      }
    ]
  },
  {
    path: '/inventory',
    component: Layout,
    children: [
      {
        path: '',
        name: 'inventory',
        component: () => import('../views/inventory/index.vue')
      }
    ]
  },
  {
    path: '/model',
    component: Layout,
    children: [
      {
        path: '',
        name: 'model',
        component: () => import('../views/model/modelManage/index.vue')
      }
    ]
  }

]

export default routes.concat(product)
