import Layout from '@/layout/index.vue'

const routes = [
  {
    path: '/product',
    component: Layout,
    children: [
      {
        path: '',
        redirect: 'productName'
      },
      {
        path: 'type',
        name: 'productName',
        component: () => import('../views/product/type/index.vue')
      },
      {
        path: 'specs',
        name: 'productSpecs',
        component: () => import('../views/product/specs/index.vue')
      },
      {
        path: 'buyer',
        name: 'productBuyer',
        component: () => import('../views/product/buyer/index.vue')
      },
      {
        path: 'producer',
        name: 'productProducer',
        component: () => import('../views/product/producer/index.vue')
      },
      {
        path: 'outbound',
        name: 'productOutbound',
        component: () => import('../views/product/outbound/index.vue')
      },
      {
        path: 'stock',
        name: 'productStock',
        component: () => import('../views/product/stock/index.vue')
      },
      {
        path: 'inventory',
        name: 'productInventory',
        component: () => import('../views/product/inventory/index.vue')
      },
      {
        path: 'overview',
        name: 'productOverview',
        component: () => import('../views/product/overview/index.vue')
      }

    ]
  }

]

export default routes
