
import axios from 'axios'

const service = axios.create({
  baseURL: '/api',
  timeout: 15000
})

service.interceptors.request.use(config => {
  return config
}, error => {
  throw Error(error)
})

service.interceptors.response.use(response => {
  const { status, data } = response
  const { code } = data
  if (status === 200 && code === 200) {
    return data.data
  } else {
    return Promise.reject(data)
  }
}, error => {
  const { data, status } = error.response
  if (status === 401) {
    window.location.href = '/login'
  }
  console.log(error)
  return Promise.reject(data)
})

export default service
