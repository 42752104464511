<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
  setup () {
    const state = reactive({
      a: ''
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style>
</style>
