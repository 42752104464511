import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from '@/store'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function isWhiteList (to) {
  const WHITE_LIST = [
    '/login',
    '/register',
    '/404'
  ]
  return WHITE_LIST.filter(i => i === to.path).length
}

const STAFF_LIST = [
  // '/model', // 权限暂时不开放
  '/inventory',
  '/stock',
  '/outbound',
  '/type',
  '/specs',
  '/company',
  '/order',
  '/'
]

function getFirstRoute (path) {
  path = path.slice(1)
  const idx = path.indexOf('/')
  return idx !== -1 ? '/' + path.slice(0, idx) : '/' + path
}

router.beforeEach(async (to, from, next) => {
  try {
    // 登录后判断是否是登录后白名单
    if (isWhiteList(to)) {
      next()
    } else {
      let userInfo = store.state.userInfo
      if (!userInfo) {
        await store.dispatch('getUserInfo')
        userInfo = store.state.userInfo
      }
      if (!userInfo.root && to.path === '/overview') {
        next({ path: '/inventory' })
      }
      // 登录后判断，是否有访问页面的权限
      if (STAFF_LIST.indexOf(getFirstRoute(to.path)) === -1 && !userInfo.root) {
        next({ path: '/404' })
      } else {
        next()
      }
    }
  } catch (err) {

  }
})

export default router
