
<template>
  <el-aside class="aside" :class="isCollapse ? 'collapse':'not-collapse'">
    <div class="menu-content">
      <el-scrollbar>
        <el-menu class="menu" router :default-active="defaultActive" unique-opened :collapse="isCollapse">
          <el-sub-menu v-for="(item,index) in curMenuList" :key="index" :index="item.path">
            <template #title>
              <span>{{item.name}}</span>
            </template>
            <el-menu-item v-for="(item,idx) in item.children" :key="idx" :index="item.path">
              <el-icon> <component :is="item.icon"></component></el-icon>
              <template #title>{{item.name}}</template>
            </el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-scrollbar>
    </div>
    <span class="button_l" @click="onLogout">退出登录</span>
  </el-aside>
</template>

<script>
import { computed, onMounted, reactive, shallowRef, toRefs } from 'vue'
import { Histogram, Guide, HotWater, KnifeFork, DocumentAdd, Lollipop, Grape, SetUp, MagicStick, Pear, Files } from '@element-plus/icons'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import store from '@/store'
import { logoutApi } from './fetch'
export default {
  components: {
    Histogram,
    Guide,
    DocumentAdd,
    Lollipop,
    Grape,
    SetUp,
    MagicStick,
    Pear,
    KnifeFork,
    HotWater
  },
  setup (props) {
    console.log(props)
    const route = useRoute()
    const router = useRouter()

    onMounted(() => {
      if (store.state.userInfo.root) {
        state.curMenuList = state.menuList
      } else {
        state.curMenuList = state.menuList2
      }
    })

    const state = reactive({
      isCollapse: false,
      curMenuList: [],
      menuList: [ // 所有权限
        {
          group: true,
          name: '版',
          path: '/',
          children: [
            { path: '/model', name: '版', icon: shallowRef(Files) }
          ]
        },
        // shallowRef 创建一个跟踪自身 .value 变化的 ref ，但不会使其值变成响应式的，把组件当成值需要将组件变成不响应式的，以减小性能损耗
        {
          group: true,
          name: '成品',
          path: '/product',
          children: [
            { path: '/product/overview', name: '概览', icon: shallowRef(Histogram) },
            { path: '/product/inventory', name: '结存', icon: shallowRef(HotWater) },
            { path: '/product/stock', name: '入库列表', icon: shallowRef(Guide) },
            { path: '/product/outbound', name: '出库列表', icon: shallowRef(KnifeFork) },
            { path: '/product/type', name: '类别库', icon: shallowRef(Lollipop) },
            { path: '/product/specs', name: '规格库', icon: shallowRef(Grape) },
            { path: '/product/buyer', name: '收货单位', icon: shallowRef(SetUp) },
            { path: '/product/producer', name: '制袋人', icon: shallowRef(MagicStick) }
          ]
        },
        {
          group: true,
          name: '原材料',
          path: '/',
          children: [
            { path: '/overview', name: '概览', icon: shallowRef(Histogram) },
            { path: '/inventory', name: '结存', icon: shallowRef(HotWater) },
            { path: '/stock', name: '入库列表', icon: shallowRef(Guide) },
            { path: '/outbound', name: '出库列表', icon: shallowRef(KnifeFork) },
            { path: '/type', name: '类别库', icon: shallowRef(Lollipop) },
            { path: '/specs', name: '规格库', icon: shallowRef(Grape) },
            { path: '/company', name: '供应商', icon: shallowRef(SetUp) },
            { path: '/template', name: '订单模板库', icon: shallowRef(MagicStick) },
            { path: '/order', name: '订单列表', icon: shallowRef(Pear) }
          ]
        }
      ],
      menuList2: [ // 仓管权限
        // shallowRef 创建一个跟踪自身 .value 变化的 ref ，但不会使其值变成响应式的，把组件当成值需要将组件变成不响应式的，以减小性能损耗
        {
          group: true,
          name: '原材料',
          path: '/',
          children: [
            { path: '/inventory', name: '结存', icon: shallowRef(HotWater) },
            { path: '/stock', name: '入库列表', icon: shallowRef(Guide) },
            { path: '/outbound', name: '出库列表', icon: shallowRef(KnifeFork) },
            { path: '/type', name: '类别库', icon: shallowRef(Lollipop) },
            { path: '/specs', name: '规格库', icon: shallowRef(Grape) },
            { path: '/company', name: '供应商', icon: shallowRef(SetUp) },
            { path: '/order', name: '订单列表', icon: shallowRef(Pear) }
          ]
        }
      ]
    })

    const defaultActive = computed(() => {
      return route.path
    })
    const onLogout = async () => {
      try {
        await logoutApi()
        ElMessage.success('退出登录成功！')
        router.push({ path: '/login' })
      } catch (e) {
        ElMessage.error(e.msg || e)
      }
    }

    return {
      ...toRefs(state),
      defaultActive,
      onLogout
    }
  }
}
</script>
<style lang="stylus" scoped>
.aside
  height 100%
  position relative
  display flex
  flex-direction: column
  overflow hidden
  .menu-content
    height 0
    overflow auto
    flex: 1
    border-bottom 1px solid #faecd8
    /deep/.menu
      margin-bottom 20px
      &:not(.el-menu--collapse) {
        // width: 200px;
        min-height: 400px;
      }
      .el-menu-item
        display flex
        align-items center
        min-width: 100px
        .el-icon
          margin-right 5px
          font-size 18px
  .button_l
    position: sticky
    bottom 0
    margin 10px 0
    padding 4px 7px
    font-size 12px
    color #F56C6C
    cursor pointer
</style>
