import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus, { ElMessage } from 'element-plus'
import { Message } from '@element-plus/icons'
import './styles/index.css'
import zhCn from './assets/zh-cn'

const app = createApp(App)
app.use(ElementPlus, { size: 'small', zIndex: 3000, locale: zhCn })
app.provide('$msg', ElMessage)
app.use(store)
app.use(router)
app.component('Message', Message)
app.mount('#app')
