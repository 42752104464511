import { createStore } from 'vuex'
import { checkPermissionApi } from './fetch'

export default createStore({
  state: {
    userInfo: null
  },
  mutations: {
    SET_USER_INFO (state, info) {
      state.userInfo = info
    }
  },
  actions: {
    async getUserInfo (context) {
      try {
        const a = await checkPermissionApi()
        console.log(a)
        context.commit('SET_USER_INFO', a)
      } catch (e) {

      }
    }
  },
  modules: {
  },
  getters: {
    userInfo: (state) => state.userInfo
  }
})
